@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #5E9925;
}

.mat-mdc-tab-labels {
  background-color: #F9FAF4;
  user-select: none;
}

.mat-mdc-tab-body-wrapper {
  height: 100%;
}

.cdk-global-scrollblock {
  overflow-x: hidden;
  overflow-y: hidden;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.17);
}



/* Chrome, Safari, Edge, Opera  - remove input arrows for number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* Tooltip */
.mdc-tooltip__surface {
  /*color: black !important;
  background: #ecf9fd !important;*/

  color: white !important;
  background: #26abd3 !important;
  padding: 15px !important;
  user-select: none;

  &.mdc-tooltip__surface-animation {
    font-size: 15px;
  }
}

.custom-selection-tooltip {
  .mdc-tooltip__surface {
    &.mdc-tooltip__surface-animation {
      min-width: 360px;
    }
  }
}

.custom-selection-tooltip-small {
  .mdc-tooltip__surface {
    &.mdc-tooltip__surface-animation {
      min-width: 170px;
    }
  }
}

.custom-interdiction-tooltip {
  .mdc-tooltip__surface {
    &.mdc-tooltip__surface-animation {
      min-width: 360px;
      background:  #26abd3 !important;
    }
  }
}


.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.mat-mdc-tab-body.mat-mdc-tab-body-active {
  overflow-y: hidden !important;
}


.mdc-radio__inner-circle {
  border-color: #5E9925 !important;
}